section.interior-contact-bar {
    &.True {
        padding-top: 300px !important;
        @media screen and (max-width: 1600px) {
            & {
                padding-top: 600px !important;
            }
        }
        @media screen and (max-width: 991px) {
            & {
                padding-top: 100px !important;
            }
        }
    }
    
    .contact-bar-wrap {
        background: linear-gradient(#F3F0EB 0%, #EFE9E2 100%);
        border: 15px solid #fff;
        border-right: none!important;
        padding: 100px 0px 100px 160px;
        @media screen and (max-width: 991px) {
            & {
                padding: 100px 20px 100px 100px;
            }
        }
        
        @media screen and (max-width: 767px) {
            & {
                padding: 100px 20px 100px 100px;
            }
        }
        
        @media screen and (max-width: 576px) {
            & {
                padding: 55px 40px;
                margin-left: 4vw;
            }
        } 

    }
    
}

section.home-contact-bar {
    &.has-faqs {
        padding-top: 100px !important;
    }
    &.has-contact {
        &:not(.has-faqs) {
            padding-top: 300px!important;
            @media screen and (max-width: 1600px) {
                & {
                    padding-top: 600px !important;
                }
            }
            @media screen and (max-width: 991px) {
                & {
                    padding-top: 100px !important;
                }
            }
        }
    }

    .contact-bar-wrap {
        background: linear-gradient(#F3F0EB 0%, #EFE9E2 100%);
        border: 15px solid #fff;
        border-right: none!important;
        padding: 100px 0px 100px 160px;
        @media screen and (max-width: 991px) {
            & {
                padding: 100px 20px 100px 100px;
            }
        }
        
        @media screen and (max-width: 767px) {
            & {
                padding: 100px 20px 100px 100px;
            }
        }
        
        @media screen and (max-width: 576px) {
            & {
                padding: 55px 40px;
                margin-left: 4vw;
            }
        } 
    }
}